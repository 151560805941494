@tailwind base;
@tailwind components;
@tailwind utilities;


.custom-gradient{
 background-image: url(./assets/Images/How-it-work-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.herosection_bg{
    background-image: url('./assets/Images/Hero-section-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
}
.tryour_bg{
    background-image: url('./assets/Images/Tryour-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
}
.about_hero{
  background-image: url('./assets//Images/About-hero-section-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.committed-bg{
  background-image: url(./assets//Images/Committed_bg_img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.fund-partners-bg-image{
  background-image: url(./assets/Images/Fund-partner-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.mutual_fund_bg{
  background-image: url('./assets//Images/Mutual-fund-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.portfolio_bg_img{
  background-image: url('./assets//Images/Portfolio-bg-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.AlternativeFundsHero_bg{
  background-image: url('../src//assets/Images/AIF-bg_img.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.typesofaif_bg{
  background-image: url('../src//assets/Images/Types-of-AIF.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.MldsHero_bg{
  background-image: url('../src//assets/Images/MLD-hero-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.corporate_fd_bg{
  background-image: url('../src/assets/Images/Corparate-fd-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.corporate_bonds{
  background-image: url('../src/assets/Images/corporate_bonds-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.soverign_bond_bg{
  background-image: url('../src//assets/Images/Sovereign-Gold-hero-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.contact_page_bg{
  background-image: url('../src/assets/Images/Contact-Us-hero.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.taxfree_bond_bg{
  background-image: url('../src/assets/Images/Taxfree-bond-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.insurance_posp_bg{
  background-image: url('../src/assets/Images/Insurance-hero-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.becomea_partner_bg{
  background-image: url('../src/assets/Images/BecamePartner-hero-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.demat_hero_bg{
  background-image: url('../src/assets/Images/Demat-hero-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.shares_hero_bg{
  background-image: url('../src/assets/Images/Shares-hero-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.p2p_hero_bg{
  background-image: url('../src/assets/Images/P2P-hero-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.insurance_hero_bg{
  background-image: url('../src/assets/Images/Insurance-hero.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.comming_soon_bg{
  background-image: url('../src/assets/Images/Cooming-soon-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.success_will_hero_bg{
  background-image: url('../src/assets/Images/SuccessWill-hero-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
}
.nunito{
    font-family: "Nunito", sans-serif;
}
.asap{
    font-family: "Asap", sans-serif;
}
.roboto{
    font-family: "Roboto", sans-serif;
}
.plus-jakarta-sans{
    font-family: "Plus Jakarta Sans", sans-serif;
}
.inter{
    font-family: "Inter", sans-serif;
}
.barlow{
  font-family: "Barlow", sans-serif;
}
.outfit{
  font-family: "Outfit", sans-serif;
}

.active{
    color: #DF5759;
}

/* Hide the spinner in WebKit browsers (e.g., Chrome, Safari) */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spinner in Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* styles.css */
.transition-max-height {
  transition: max-height 0.3s ease-in-out;
}
html{
  scroll-behavior: smooth;
}


 
 .sign_btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #DF5759;
    overflow: hidden;
    cursor: pointer;
    border: none;
  }
  
  .sign_btn:after {
    content: " ";
    width: 0%;
    height: 100%;
    background: #014579;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
  }
  
  .sign_btn:hover::after {
    right: auto;
    left: 0;
    width: 100%;
    color: #fff;
  }
  
  .sign_btn span {
    text-align: center;
    text-decoration: none;
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    z-index: 20;
    transition: all 0.3s ease-in-out;
  }
  
  .sign_btn:hover span {
    color:#ffffff;
    animation: scaleUp 0.3s ease-in-out;
  }
  
  @keyframes scaleUp {
    0% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(0.95);
    }
  
    100% {
      transform: scale(1);
    }
  }
  
 
